import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/Page'
import MainContent from '../components/MainContent'
import FlexLink from '../components/FlexLink'

class IndexRoute extends React.Component {
  render() {
    return (
      <Page {...this.props}>
        <MainContent>
          <p>
            Hi, I'm Randy - a Los Angeles and remote technology consultant and entrepreneur.
          </p>
          <p>
            Find out more about me, <FlexLink to="/work-with-me">work with me</FlexLink>, or have a look at my social media
            profiles below.
          </p>
        </MainContent>
      </Page>
    )
  }
}

export default IndexRoute

export const siteQuery = graphql`
  fragment siteQuery on Query {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          facebook
          linkedin
          github
          stackoverflow
          instagram
          skype
          slack
          paypal
          venmo
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query IndexQuery {
    ...siteQuery
  }
`
